import React from 'react'
import Header from '../../Modules/Header/Header'
import MainBlock from '../../Modules/MainBlock/MainBlock'
import Advantages from '../../Modules/Advantages/Advantages'
import Offers from '../../Modules/Offers/Offers'
import AboutUs from '../../Modules/AboutUs/AboutUs'
import Form from '../../Modules/Form/Form'
import Contact from '../../Modules/Contact/Contact'
import Footer from '../../Modules/Footer/Footer'
import FooterCopyright from '../../Modules/Footer/FooterCopyright'
import styles from '../../Modules/Form/Form.module.css'
import NewSteps from '../../Modules/NewSteps/NewSteps'
import SpecOffer from '../../Modules/SpecOffer/SpecOffer'

const MainPage = () => {
  return (
    <div>
      <Header />
      <MainBlock />
      <Advantages />
      <SpecOffer />
      <Offers />
      <Form
        wrapColor={styles.wrap}
        styleTitle={styles.box_title}
        styleSubtitle={styles.box_subTitle}
        styleLbl={styles.box_form__box_lbl}
        styleButtonWrap={styles.box_form__btnBox}
        styleButton={styles.box_form__btnBox_btn}
      />
      <AboutUs />
      <NewSteps />
      <Form
        wrapColor={styles.wrap_black}
        styleTitle={`${styles.box_title} ${styles.box_title__white}`}
        styleSubtitle={`${styles.box_subTitle} ${styles.box_title__white}`}
        styleLbl={`${styles.box_form__box_lbl} ${styles.box_form__box_lbl__white}`}
        styleButtonWrap={`${styles.box_form__btnBox} ${styles.box_form__btnBox_second}`}
        styleButton={`${styles.box_form__btnBox_btn} ${styles.box_form__btnBox_btn__second}`}
      />
      <Contact />
      <Footer />
      <FooterCopyright />
    </div>
  )
}

export default MainPage
