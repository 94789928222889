import React from 'react'
import './Button.css'
const Button = ({ text, onClick }) => {
  return (
    <div onClick={onClick} className="button-wrapper">
      <button className="button">{text}</button>
    </div>
  )
}

export default Button
