import React, { useEffect, useState } from 'react'
import styles from './WebHeader.module.css'
import { header } from '../../../Constants/Constants'
import { Link } from 'react-scroll'
import _ from 'lodash'

const WebHeader = () => {
  const [show, setShow] = useState(null)

  const controlNavBar = _.throttle(() => {
    const currentScrollY = window.scrollY
    if (lastScrollY < currentScrollY) {
      setShow('true')
    } else {
      setShow('false')
    }
    lastScrollY = currentScrollY
  }, 200)

  let lastScrollY = window.scrollY

  useEffect(() => {
    const handleScroll = () => {
      controlNavBar()
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <header
      className={`${styles.wrap} ${
        show === 'false'
          ? styles.wrapShow
          : show === 'true'
          ? styles.wrapHidden
          : null
      }`}
    >
      <div className={`customContainer ${styles.container}`}>
        <img src={header.logo} alt="logo" className={styles.container_logo} />

        <ul className={styles.container_linksWrap}>
          <li className={styles.container_linksWrap__link}>
            <Link to={'offers'}>{header.link1}</Link>
          </li>
          <li className={styles.container_linksWrap__link}>
            <Link to={'about'}>{header.link2}</Link>
          </li>
          <li className={styles.container_linksWrap__link}>
            <Link to={'steps'}>{header.link3}</Link>
          </li>
          <li className={styles.container_linksWrap__link}>
            <Link to={'contact'}>{header.link4}</Link>
          </li>
        </ul>
        <Link to={'form'}>
          <button className={styles.container_btn}>{header.button}</button>
        </Link>
      </div>
    </header>
  )
}

export default WebHeader
