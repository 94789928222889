import React, { useState } from 'react'
import './MainBlock.css'
import {header, mainBlock} from '../../Constants/Constants'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import Button from '../../Components/Button/Button'
import { baseURL, chatId, validator } from '../../Constants/urls'
import { useNavigate } from 'react-router-dom'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { useForm } from 'react-hook-form'
import styles from "../Header/WebHeader/WebHeader.module.css";
import {Link} from "react-scroll";
const MainBlock = () => {
  const [phone, setPhone] = useState('')
  const [isBlurred, setIsBlurred] = useState(false)

  const navigate = useNavigate()

  const phoneUtil = PhoneNumberUtil.getInstance()
  const isPhoneValid = () => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
    } catch (error) {
      return false
    }
  }
  const isValid = isPhoneValid(phone)

  const { handleSubmit, reset } = useForm({ mode: 'all' })
  const sendTelegramMessage = () => {
    let msg = `Заявка на консультацію:\nТелефон - ${phone}`

    if (isValid) {
      fetch(baseURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          validator: validator,
          chat_id: chatId,
          message: msg,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then((data) => {
          console.log('Message sent successfully')
        })
        .catch((error) => {
          console.error('Error sending message:', error)
        })
      reset()
      navigate('/thankyou')
    }
  }

  return (
    <div className="mainBlock">
      <div className="mainBlock-mobTop">
        <div>
          <p className="mainBlock-contact__text-text">
            <a className="mainBlock-contact__text-text" style={{'font-weight': 'bold'}} href={"tel:" + mainBlock.contact.phoneCall}>{mainBlock.contact.phone}</a>
          </p>
        </div>
        <div>
          <Link to={'form'}>
            <button className={styles.container_btn} style={{display: 'flex'}}>{header.button}</button>
          </Link>
        </div>
      </div>
      <div className="mainBlock-wrapper customContainer">
        <div className="mainBlock-contact">
          <div className="mainBlock-contact__item">
            <svg
              className="mainBlock-contact__img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 2.16113C7.8 2.16113 4 5.38113 4 10.3611C4 13.5411 6.45 17.2811 11.34 21.5911C11.72 21.9211 12.29 21.9211 12.67 21.5911C17.55 17.2811 20 13.5411 20 10.3611C20 5.38113 16.2 2.16113 12 2.16113ZM12 12.1611C10.9 12.1611 10 11.2611 10 10.1611C10 9.06113 10.9 8.16113 12 8.16113C13.1 8.16113 14 9.06113 14 10.1611C14 11.2611 13.1 12.1611 12 12.1611Z"
                fill="black"
              />
            </svg>
            <div className="mainBlock-contact__text">
              <h2 className="mainBlock-contact__text-title">
                {mainBlock.contact.adressName}
              </h2>
              <p className="mainBlock-contact__text-text">
                {mainBlock.contact.adress}
              </p>
            </div>
          </div>
          <div className="mainBlock-contact__item">
            <svg
              className="mainBlock-contact__img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.2218 15.2681L16.6818 14.9781C16.0718 14.9081 15.4718 15.1181 15.0418 15.5481L13.2018 17.3881C10.3718 15.9481 8.05179 13.6381 6.61179 10.7981L8.46179 8.94807C8.89179 8.51807 9.10179 7.91807 9.03179 7.30807L8.74179 4.78807C8.62179 3.77807 7.77179 3.01807 6.75179 3.01807H5.02179C3.89179 3.01807 2.95179 3.95807 3.02179 5.08807C3.55179 13.6281 10.3818 20.4481 18.9118 20.9781C20.0418 21.0481 20.9818 20.1081 20.9818 18.9781V17.2481C20.9918 16.2381 20.2318 15.3881 19.2218 15.2681Z"
                fill="black"
              />
            </svg>
            <div className="mainBlock-contact__text">
              <h2 className="mainBlock-contact__text-title">
                {mainBlock.contact.phoneName}
              </h2>
              <p className="mainBlock-contact__text-text">
                <a className="mainBlock-contact__text-text" href={"tel:" + mainBlock.contact.phoneCall}>{mainBlock.contact.phone}</a>
              </p>
            </div>
          </div>
        </div>
        <h1 className="mainBlock-title">{mainBlock.title}</h1>
        <h1 className="mainBlock-text">{mainBlock.text}</h1>
        <form
          className="mainBlock-form"
          onSubmit={handleSubmit(sendTelegramMessage)}
        >
          <div
            className="mainBlock-form__phone"
            style={
              isBlurred && !isValid
                ? {
                    border: '2px solid red',
                    boxShadow: 'inset 0px 0px 20px 1px rgba(255,0,0,.3)',
                  }
                : isBlurred && isValid
                ? {
                    border: '2px solid green',
                    boxShadow: 'inset 0px 0px 20px 10px rgba(100,255,100,.3)',
                  }
                : { border: 'none' }
            }
          >
            <PhoneInput
              defaultCountry="ua"
              className="mainBlock-form__phone-inp"
              onChange={(phone) => setPhone(phone)}
              autoComplete={'off'}
              onBlur={() => setIsBlurred(true)}
            />
          </div>
          <Button text="Отримати консультацію" />
        </form>
      </div>
    </div>
  )
}

export default MainBlock
