import React, {Component} from 'react'
import Header from '../../Modules/Header/Header'
import MainBlock from '../../Modules/MainBlock/MainBlock'
import Advantages from '../../Modules/Advantages/Advantages'
import Offers from '../../Modules/Offers/Offers'
import AboutUs from '../../Modules/AboutUs/AboutUs'
import Form from '../../Modules/Form/Form'
import Contact from '../../Modules/Contact/Contact'
import Footer from '../../Modules/Footer/Footer'
import FooterCopyright from '../../Modules/Footer/FooterCopyright'
import styles from '../../Modules/Form/Form.module.css'
import NewSteps from '../../Modules/NewSteps/NewSteps'
import SpecOffer from '../../Modules/SpecOffer/SpecOffer'

class PolicyPage extends Component {

    componentDidMount() {
        const linkList = document.getElementsByTagName('a');

        for (let el of linkList)
        {
            if(el.href.length > 0)
                continue;

            const clone = el.cloneNode(true); el.replaceWith(clone);

            clone.addEventListener('click', function (){
                window.location.href = '/';
            })
        }
    }

    render() {
        return (
            <div>
                <Header />
                <div className="customContainer" style={{'margin-top': '20px'}} dangerouslySetInnerHTML={{__html: `<h1><strong>Політика конфіденційності</strong></h1>
          <p><span style="font-weight: 400;">Адреса нашого веб-сайту: https://www.rd-generator.com.ua/</span></p>
          <h2><strong>Медіафайли</strong></h2>
          <p><span style="font-weight: 400;">Якщо ви зареєстрований користувач і завантажуєте фотографії на сайт, вам, можливо, слід уникати завантаження зображень із метаданими EXIF, оскільки вони можуть містити дані вашого місця розташування за GPS. Відвідувачі можуть отримати цю інформацію, завантаживши зображення з сайту.</span></p>
          <h2><strong>Куки</strong></h2>
          <p><span style="font-weight: 400;">Якщо ви залишаєте коментар на нашому сайті, ви можете увімкнути збереження вашого імені, адреси email і веб сайту в куки. Це робиться для вашої зручності, щоб не заповнювати дані знову при повторному коментуванні. Ці куки зберігаються протягом одного року.</span></p>
          <p><span style="font-weight: 400;">Якщо у вас є обліковий запис на сайті та ви ввійдете в нього, ми встановимо тимчасовий кукі для визначення підтримки кукі вашим браузером, кукі не містить жодної особистої інформації та видаляється під час закриття вашого браузера.</span></p>
          <p><span style="font-weight: 400;">Під час входу в обліковий запис ми також встановлюємо кілька кукі з даними входу та налаштуваннями екрана. Куки входу зберігаються протягом двох днів, куки з налаштуваннями екрана &ndash; рік. Якщо ви виберете можливість &ldquo;Запам&rsquo;ятати мене&rdquo;, дані про вхід зберігатимуться протягом двох тижнів. Під час виходу з облікового запису куки входу буде видалено.</span></p>
          <p><span style="font-weight: 400;">Під час редагування або публікації статті в браузері буде збережено додатковий кукі, він не містить персональних даних і містить тільки ID запису, відредагованого вами, спливає через 1 день.</span></p>
          <h2><strong>Вбудований вміст інших веб сайтів, що вбудовується</strong></h2>
          <p><span style="font-weight: 400;">Статті на цьому сайті можуть містити вміст, що вбудовується (наприклад, відео, зображення, статті та ін.), такий вміст поводиться так само, як якби відвідувач зайшов на інший сайт.</span></p>
          <p><span style="font-weight: 400;">Ці сайти можуть збирати дані про вас, використовувати куки, впроваджувати додаткове відстеження третьою стороною і стежити за вашою взаємодією з впровадженим вмістом, включно з відстеженням взаємодії, якщо у вас є обліковий запис і ви авторизувалися на тому сайті.</span></p>
          <h2><strong>З ким ми ділимося вашими даними</strong></h2>
          <p><span style="font-weight: 400;">Якщо ви запросите скидання пароля, ваш IP буде вказано в email-повідомленні про скидання.</span></p>
          <h2><strong>Як довго ми зберігаємо ваші дані</strong></h2>
          <p><span style="font-weight: 400;">Якщо ви залишаєте коментар, то сам коментар і його метадані зберігаються не визначено довго. Це робиться для того, щоб визначати і схвалювати наступні коментарі автоматично, замість поміщення їх у чергу на схвалення.</span></p>
          <p><span style="font-weight: 400;">Для користувачів з реєстрацією на нашому сайті ми зберігаємо ту особисту інформацію, яку вони вказують у своєму профілі. Усі користувачі можуть бачити, редагувати або видалити свою інформацію з профілю в будь-який час (крім імені користувача). Адміністрація вебсайту також може бачити і змінювати цю інформацію.</span></p>
          <h2><strong>Які у вас права на ваші дані</strong></h2>
          <p><span style="font-weight: 400;">За наявності облікового запису на сайті або якщо ви залишали коментарі, то ви можете запросити файл експорту персональних даних, які ми зберегли про вас, включаючи надані вами дані. Ви також можете запросити видалення цих даних, це не включає дані, які ми зобов&rsquo;язані зберігати в адміністративних цілях, згідно із законом або з метою безпеки.</span></p>
          <h2><strong>Куди ми відправляємо ваші дані</strong></h2>
          <p><span style="font-weight: 400;">Коментарі користувачів можуть перевірятися автоматичним сервісом визначення спаму.</span></p>
          <p>&nbsp;</p>`}}>
                </div>
                <Footer />
                <FooterCopyright />
            </div>
        )
    }
}

export default PolicyPage
