import React from 'react'
import './Vacancies.scss'

export default function Vacancies({ data }) {
  return (
    <div className="vacancies customContainer" id={'offers'}>
      <div className="vacancies__title">Що ми пропонуємо</div>
      <div className="vacancies__cards">
        {data.cards.map((el) => {
          return (
            <div className="vacancies__card vacanciesCard">
              <div className="vacanciesCard__top">
                <img
                  src={el.photo}
                  alt="vacancie"
                  className="vacanciesCard__photo"
                />
              </div>
              <div className="vacanciesCard__bottom">
                <div className="vacanciesCard__text">
                  <div className="vacanciesCard__title">{el.title}</div>
                  <div className="vacanciesCard__description">
                    <ul className="vacancies_ul">
                      {el.text.map((line, index) => (
                        <li key={index}>• {line}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="vacanciesCard__interactive">
                  <div className="vacanciesCard__price">{el.price.number}</div>
                  <a href="#form">
                    <button className="vacanciesCard__btn">
                      <svg
                        width="27"
                        height="24"
                        viewBox="0 0 27 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.5 6C19.5 7.66 20.84 9 22.5 9C24.16 9 25.5 7.66 25.5 6C25.5 4.34 24.16 3 22.5 3C20.84 3 19.5 4.34 19.5 6Z"
                          fill="#101828"
                        />
                        <path
                          d="M19.5151 10.0149C20.3475 10.634 21.38 11 22.5 11V17C22.5 19.2091 20.7091 21 18.5 21H6.5C4.29086 21 2.5 19.2091 2.5 17V9C2.5 6.79086 4.29086 5 6.5 5H17.5995C17.5342 5.323 17.5 5.65741 17.5 6C17.5 7.0051 17.7948 7.93981 18.3028 8.72263L12.9999 12.0307L6.95997 8.26279C6.55276 8.00876 6.01672 8.13293 5.76268 8.54014C5.50865 8.94735 5.63282 9.48339 6.04003 9.73742L12.4889 13.7605C12.6481 13.8598 12.8269 13.9013 13.0009 13.8907C13.1743 13.9009 13.3525 13.8593 13.5111 13.7604L19.5151 10.0149Z"
                          fill="#101828"
                        />
                      </svg>

                      {data.btn}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
