import React from 'react'
import { advantagesData } from '../../Constants/Constants'
import './Advantages.css'

const Advantages = () => {
  return (
    <div className="advantages">
      <div className="advantages-wrapper customContainer">
        {advantagesData.map((item, index) => (
          <div className="advantages-wrapper">
            <div key={index} className="advantages-item">
                {index !== advantagesData.length - 1 && (
                    <div className="advantages-item__vertical"></div>
                )}
              <div className="advantages-item__wrapper">
                <img
                  src={item.imgSrc}
                  alt=""
                  className="advantages-item__img"
                />
                <h2 className="advantages-item__title">{item.title}</h2>
              </div>
              <p className="advantages-item__text">{item.text}</p>
            </div>

          </div>
        ))}
      </div>
    </div>
  )
}

export default Advantages
