import React from 'react'
import './Offers.scss'
import { offers } from '../../Constants/Constants.js'
import Vacancies from './Vacancies/Vacancies.js'

export default function Offers() {
  if(offers.type === 'vacancies') {
    return (
      <Vacancies data={offers}/>
    )
  }
  return (
    <div className="offers customContainer" id={'offers'}>
      <div className="offers__title">Що ми пропонуємо</div>
      <div
        className={
          offers.cards.length === 2
            ? 'offers__cards offers__cards_more'
            : 'offers__cards offers__cards_two'
        }
      >
        <div className="offers__card offersForm">
          <div>
            <div className="offersForm__title">Онлайн-запис</div>
            <div className="offersForm__subTitle">
              Наш менеджер перетелефонує Вам якнайшвидше
            </div>
          </div>

          <a
            href="https://b799964.alteg.io/company/752027/personal/menu?fbclid=PAAaaERSx_iYvBUBzpriFYjWgwKLnLlgUEmeB1WjcBhgG7Z1gbIX2B2drgNjw_aem_AXoBNdxmtCr_u_lXR7p-JCunRiAcpqdKk7Ikg4dCeSPRW6x5yV4TZfVAVrO9qfb39Tc&referrer=https:%2F%2Fl.instagram.com%2F&o=m-1"
            className="offersForm__btnContainer"
          >
            <button id="offersForm__btn">Записатись</button>
          </a>
        </div>
        {offers.cards.map((el, index) => {
          return (
            <div className="offers__card offersOffer">
              <div className="offersOffer__badge">
                <div className="offersOffer__circle"></div>
                <div>Послуга {index + 1}</div>
              </div>
              <img src={el.photo} alt="offer" />
              <div className="offersOffer__info">
                <div className="offersOffer__title">{el.title}</div>
                <div className="offersOffer__text">
                  {el.text.length >= 100
                    ? el.text.slice(0, 100) + '...'
                    : el.text}
                </div>
                <div className="offersOffer__price">
                  від <span>{el.price}</span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
