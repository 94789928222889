import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MainPage from './Pages/MainPage/MainPage'
import Thank from './Pages/ThankyouPage/Thank'
import ReCAPTCHA from 'react-google-recaptcha'
import React from 'react'
import PolicyPage from "./Pages/PolicyPage/PolicyPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<MainPage />} />
          <Route path="/thankyou" element={<Thank />} />
          <Route path="/policy" element={<PolicyPage />} />
        </Routes>
      </Router>
      <ReCAPTCHA
        sitekey="6LceyBUpAAAAAJhzgCCekeruQ63xiMwp_9L5pfbo"
        size="invisible"
      />
    </div>
  )
}

export default App
