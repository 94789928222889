import React, { useState } from 'react'
import styles from './Form.module.css'
import { form } from '../../Constants/Constants'
import { useForm } from 'react-hook-form'
import { baseURL, chatId, validator } from '../../Constants/urls'
import { PhoneInput } from 'react-international-phone'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { useNavigate } from 'react-router-dom'

const Form = ({
  wrapColor,
  styleTitle,
  styleSubtitle,
  styleLbl,
  styleButtonWrap,
  styleButton,
}) => {
  const [phone, setPhone] = useState('')
  const [inName, setInName] = useState('')
  const [isBlurred, setIsBlurred] = useState(false)
  const [isBlurredName, setIsBlurredName] = useState(false)

  const phoneUtil = PhoneNumberUtil.getInstance()
  const isPhoneValid = () => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
    } catch (error) {
      return false
    }
  }
  const isValid = isPhoneValid(phone)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: 'all' })

  const navigate = useNavigate()

  const onSubmit = ({ name }) => {
    let msg = `Заявка на консультацію:\nІм'я - ${name}\nТелефон - ${phone}`

    if (name && isValid) {
      fetch(baseURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          validator: validator,
          chat_id: chatId,
          message: msg,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then((data) => {
          reset()
        })
        .catch((error) => {
          console.error('Помилка при виконанні запиту:', error)
        })
      navigate('/thankyou')
    }
  }

  let redBord = {
    border: '2px solid red',
    boxShadow: 'inset 0px 0px 20px 1px rgba(255,0,0,.3)',
  }
  let greenBord = {
    border: '2px solid green',
    boxShadow: 'inset 0px 0px 20px 10px rgba(100,255,100,.3)',
  }

  return (
    <div className={wrapColor} id={'form'}>
      <div className={`customContainer ${styles.box}`}>
        <h3 className={styleTitle}>{form.title}</h3>
        <h6 className={styleSubtitle}>{form.subTitle}</h6>
        <form
          action=""
          className={styles.box_form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.box_form__box}>
            <label htmlFor="" className={styleLbl}>
              {form.inputLabel1}
            </label>{' '}
            <input
              type="text"
              placeholder={form.inputPlaceholder1}
              className={styles.box_form__box_inp}
              onInput={(name) => setInName(name.target.value)}
              style={
                errors.name
                  ? redBord
                  : !errors.name && isBlurredName && inName
                  ? greenBord
                  : { border: 'none' }
              }
              onFocus={() => setIsBlurredName(true)}
              autoComplete={'off'}
              {...register('name', {
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
            />
          </div>
          <div className={styles.box_form__box}>
            <label htmlFor="" className={styleLbl}>
              {form.inputLabel2}
            </label>{' '}
            <PhoneInput
              defaultCountry="ua"
              className={styles.box_form__box_inp}
              autoComplete={'off'}
              onChange={(phone) => setPhone(phone)}
              style={
                !isValid && isBlurred
                  ? redBord
                  : isValid && isBlurred
                  ? greenBord
                  : { border: 'none' }
              }
              onBlur={() => setIsBlurred(true)}
            />
          </div>
          <div className={styleButtonWrap}>
            <button className={styleButton}>{form.button}</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Form
