import React from 'react'
import './AboutUs.scss'
import socials from './socials.js'
import {aboutUs} from '../../Constants/Constants.js'


export default function AboutUs() {
  return (
    <div className="aboutUs__container" id={'about'}>
      <div className="customContainer aboutUs">
        <div className="aboutUs__imgs">
          <div className="aboutUs__imgs_left">
            {aboutUs.photos.slice(0, 1).map((el) => {
              return <img src={el} alt="description" className="aboutUs__img" />
            })}
          </div>
          <div className="aboutUs__imgs_right">
            {aboutUs.photos.slice(1).map((el) => {
              return <img src={el} alt="description" className="aboutUs__img" />
            })}
          </div>
        </div>
        <div className="aboutUs__info">
          <div className="aboutUs__title">{aboutUs.title}</div>
          <div className="aboutUs__text" dangerouslySetInnerHTML={{__html: aboutUs.text}}>
          </div>
          <div className="aboutUs__footer">
            <div className="aboutUs__rating">


            </div>
            <div className="aboutUs__socials">
              {aboutUs.mySocials.map((el) => {
                return <a href={el.link}>{socials(el.name)}</a>
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
