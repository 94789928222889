import React from 'react'
import './Copyright.css'

const FooterCopyright = () => {
  return (
    <div className="copyright">
      <div className="customContainer footer_copyright">
        <div className="copyright_text_left">2024 © Всі права захищенно</div>
        <div className="copyright_dots">&#x2022;</div>

        <a
          className="copyright_link"
          href="https://www.znaesh.agency/"
        >
          <div className="copyright_text">
            Розроблено:{' '}
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M19.7088 6.65388C17.5594 1.56702 11.6935 -0.812619 6.6085 1.33666C1.52157 3.48603 -0.858066 9.3518 1.2913 14.4369C3.44067 19.5238 9.30643 21.9034 14.3915 19.7542C19.4784 17.6048 21.858 11.7408 19.7088 6.65388ZM6.31463 4.68991L14.3878 4.19937L15.352 5.39576L9.2821 10.3497L9.13417 7.90081L10.9671 6.33559L6.43067 6.61081L6.31463 4.68991ZM10.7219 17.3969C7.04481 17.6197 5.13508 14.8282 4.59769 14.2722L6.36517 12.9896C6.90246 13.5438 7.99778 15.4778 10.6002 15.3899C12.9443 15.3113 13.9497 13.5363 13.8729 12.2819C13.8392 11.7165 13.4984 10.8384 12.2665 10.5014L13.9122 9.15523C15.5654 9.88541 15.9998 11.402 16.0503 12.2332C16.172 14.2422 14.8165 17.1479 10.7219 17.3969Z"
                  fill="#9F9F9F"
                />
              </svg>
            </span>{' '}
            Знаєш Agency
          </div>
        </a>
      </div>
    </div>
  )
}

export default FooterCopyright
