import React from 'react'
import './Footer.css'
import { Link } from 'react-scroll'
import { header } from '../../Constants/Constants'
import { contact, footer } from '../../Constants/Constants'

const Footer = () => {
  return (
    <div className="footer_block">
      <div className="customContainer footer">
        <div className="footer_company">
          <img className="footer_logo" src={footer.footerLogo} alt="" />
          <p className="footer_info_company">{footer.footerDescription}</p>
        </div>
        <div className="footer_menu">
          <h2 className="footer_heading_menu">Меню</h2>
          <Link offset={-50} to={'offers'}>
            <p className="footer_menu_link">{header.link1}</p>
          </Link>
          <Link offset={-50} to={'about'}>
            <p className="footer_menu_link">{header.link2}</p>
          </Link>
          <Link offset={-50} to={'steps'}>
            <p className="footer_menu_link">{header.link3}</p>
          </Link>
          <a href={'/policy'} style={{'text-decoration': 'none'}}>
            <p className="footer_menu_link">Політика конфіденційності</p>
          </a>
        </div>
        <div className="footer_contact">
          <h2 className="footer_heading_contact">Контакти</h2>
          <div>
            <h3 className="footer_info_contact">Адреса:</h3>
            <a
              className="footer_link"
              target="_blank"
              rel="noopener noreferrer"
              href={contact.addressLink}
            >
              <p className="footer_subheading_contact">{contact.address}</p>
            </a>
          </div>
          <div>
            <h3 className="footer_info_contact">Е-мейл:</h3>
            <a
              className="footer_link"
              target="_blank"
              rel="noopener noreferrer"
              href={contact.emailLink}
            >
              <p className="footer_subheading_contact">{contact.email}</p>
            </a>
          </div>
          <div>
            <h3 className="footer_info_contact">Телефон:</h3>
            <a
              className="footer_link"
              target="_blank"
              rel="noopener noreferrer"
              href={contact.numberLink}
            >
              <p className="footer_subheading_contact">{contact.number}</p>
            </a>
          </div>
          <div>
            <h3 className="footer_info_contact">Графік роботи:</h3>
            <p className="footer_subheading_contact">{contact.hours}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
