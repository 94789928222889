import { Logo } from '../Assets/Images/Header'

import { offer_1, offer_2, offer_3 } from '../Assets/Images/images.js'

import {
  aboutUs_1,
  aboutUs_2,
  aboutUs_3,
  aboutUs_4,
  aboutUs_5,
} from '../Assets/Images/images'

import {
  step_1,
  step_2,
  step_3,
  step_4,
  step_5,
  step_6,
} from '../Assets/Icons/icons'
import {
  Gluing,
  Miscount,
  Warranty,
} from '../Assets/Icons/Advantages/Advantages'

const header = {
  logo: Logo,
  link1: 'Що пропонуємо',
  link2: 'Про нас',
  link3: 'Етапи роботи',
  link4: 'Контакти',
  button: 'Зв’язатись',
}

const mainBlock = {
  contact: {
    adressName: 'Адреса:',
    adress: '79495, м. Винники, вул. Сахарова, буд. 10-А',
    phoneName: 'Телефон:',
    phone: '+ 38 068 351 31 31',
    phoneCall: '+380683513131',
  },
  title: 'Продаж генераторів',
  text: 'Широкий вибір генераторів. Безперебійне постачання електроенергії. Наші генератори ідеально підходять для будівельних майданчиків, подій на відкритому повітрі, екстрених ситуацій та інших випадків, де потрібне безперебійне електропостачання.',
}

export const advantagesData = [
  {
    imgSrc: Warranty,
    title: 'Товар в наявності',
    // text: "Співпраця з нашим агентством дає доступ до більшого кола вакансій та розвитку кар'єри.",
  },
  {
    imgSrc: Gluing,
    title: 'Генератори проходять обкатку',
    // text: "Професійні консультанти допомагають із резюме, співбесідою та кар'єрним розвитком.",
  },
  {
    imgSrc: Miscount,
    title: 'Передпродажна  підготовка',
    // text: "Полегшуємо пошук роботи, оскільки берем на себе адміністративні і організаційні обов'язки.",
  },
]

const about = {
  title: {
    titleFirst: '',
    titleSecond: '',
  },
  text: {},
}
const offers = {
  type: 'vacancies', //offers or vacancies
  btn: 'Залишити заявку',
  cards: [
    {
      photo: offer_1,
      title: 'PRAMAST VR-R30 Diesel-Electric Generator 30KW',
      text: [
        'Потужність: 30кВт.',
        'Виходи: 230/380 вольт.',
        'Номінальний струм: 54 А',
        'Марка двигуна: Ricardo 4 -тактний з водяним охолодженням',
        'Система управління: AMF digital panel 6120',
        'Напруга стартера: 12V 60Ah',
        'Витрата палива: 100% навантаження 12,3 л/год або 251 г/кВт.год',
        'Запуск: ручний (з кнопки)/автоматичний ( АВР треба встановлювати додатково, в комплект не входить).',
        'Розмір ДхШхВ, 2000×850×1150мм.',
        'Вага 870кг.',
      ],
      price: {
        number: '300 000 грн',
        per: '/ місяць',
      },
      greenCard: '4.5€ в год.',
      country: {
        flag: 'Lithuania',
        title: 'Клайпеда, Литва',
      },
    },
    {
      photo: offer_2,
      title: 'PRAMAST VG-R50 Diesel-Electric Generator 50kW',
      text: [
        'Потужність: 50кВт.',
        'Виходи: 230/380 вольт.',
        'Номінальний струм: 90 А',
        'Марка двигуна: Ricardo 4 -тактний з водяним охолодженням',
        'Система управління: AMF digital panel 6120',
        'Напруга стартера: 24V',
        'Витрата палива: При 100% навантаженні 14,9 л/год або 251 г/кВт.год',
        'Запуск: ручний (з кнопки)/автоматичний ( АВР треба встановлювати додатково, в комплект не входить).',
        'Розмір ДхШхВ, 2150×900×1200мм.',
        'Вага 1100кг.',
      ],
      price: {
        number: '400 000 грн',
        per: '/ місяць',
      },
      greenCard: '15€ в год.',
      country: {
        flag: 'Europe',
        title: '5+ країн ЄС',
      },
    },
    {
      photo: offer_3,
      title: 'PRAMAST VG-100 kW Diesel Electric Generator 100kW',
      text: [
        'Потужність: 100кВт.',
        'Номінальний струм: 190 А',
        'Виходи: 230/380 вольт.',
        'Марка двигуна: Ricardo 4 -тактний з водяним охолодженням',
        'Система управління: AMF digital panel 6120',
        'Напруга стартера: 24V',
        'Витрата палива: При 100% навантаженні 32,3 л/год або 224 г/кВт.год',
        'Запуск: ручний (з кнопки)/автоматичний ( АВР треба встановлювати додатково, в комплект не входить).',
        'Розмір ДхШхВ, 2800×1050×1430мм.',
        'Вага 1800кг.',
      ],
      price: {
        number: '600 000 грн',
        per: '/ місяць',
      },
      greenCard: '15€ за кв. м',
      country: {
        flag: 'Lithuania',
        title: 'Клайпеда, Литва',
      },
    },
  ],
}
const steps = [
  {
    text: 'Проведення консультації',
    img: step_1,
  },
  {
    text: 'Підбір вакансії',
    img: step_2,
  },
  {
    text: 'Підписання договору',
    img: step_3,
  },
  {
    text: 'Супровід до місця роботи',
    img: step_4,
  },
  {
    text: 'Проведення інструктажу',
    img: step_5,
  },
  {
    text: 'Інтеграція на робочому місці',
    img: step_6,
  },
]
const aboutUs = {
  title: 'R.Development',
  photos: [aboutUs_3, aboutUs_1, aboutUs_2, aboutUs_4, aboutUs_5],
  rating: 4.8,
  mySocials: [
    {
      name: 'instagram',
      link: 'https://www.instagram.com/generator_ukrbud/?igsh=dGxzMnd1ZHZlZDUz&utm_source=qr',
    },
    {
      name: 'telegram',
      link: 'https://t.me/+380683513131',
    },
    {
      name: 'facebook',
      link: 'https://www.facebook.com/profile.php?id=100055273123873',
    },
  ],
  text: `Компанія спеціалізується на оренді і продажу генераторів. Ми надаємо широкий вибір потужних та надійних генераторів для вирішення різних потреб. Наші продукти ідеально підходять для будівельних майданчиків, подій на відкритому повітрі, екстрених ситуацій та багатьох інших випадків, де потрібне безперебійне електропостачання. Наша компанія пропонує як нові, так і генератори які були використані, високої якості за конкурентними цінами.`,
}

const form = {
  title: 'Запис на консультацію',
  subTitle: 'Наш менеджер перетелефонує Вам якнайшвидше',
  inputLabel1: 'Ім’я',
  inputPlaceholder1: 'Введіть ваше ім’я',
  inputLabel2: 'Номер телефону ',
  button: 'Записатись',
}

const contact = {
  address: '79495, м. Винники, вул. Сахарова, буд. 10-А.я',
  addressLink: 'https://maps.app.goo.gl/jrRXLk27zqQtHq3Q7',
  number: '+38 068 351 31 31',
  email: 'ex.ukrbud@gmail.com',
  numberLink: 'tel:+48 785 004 888',
  emailLink: 'mailto:ex.ukrbud@gmail.com',
  hours: 'Пн-Пт 09:00-18:00',
  hoursWeekend: '',
  mapLink:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2574.2182293067012!2d24.14398077652018!3d49.819563471478546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473ac26691b028fb%3A0x9912aa96137b7462!2z0JbQmiDQoNGW0LTQvdC40Lkg0LTRltC8!5e0!3m2!1suk!2sua!4v1713544844327!5m2!1suk!2sua',
  socialLink1: 'https://www.facebook.com/profile.php?id=100055273123873',
  socialLink2:
    'https://www.instagram.com/generator_ukrbud/?igsh=dGxzMnd1ZHZlZDUz&utm_source=qr',
  socialLink3: 'https://t.me/+380683513131',
}

const footer = {
  footerLogo: Logo,
  footerDescription:
    'Ми пропонуємо різноманітний вибір потужних та надійних генераторів, які ідеально підходять для вирішення різних завдань.',
}

export {
  header,
  about,
  offers,
  form,
  mainBlock,
  contact,
  footer,
  steps,
  aboutUs,
}
