import React from 'react'
import styles from './SpecOffer.module.css'
import { Link } from 'react-scroll'

const SpecOffer = () => {
  const cards = [
    'Генератори 30, 50 та 100 кВт',
    'В наявності м. Львів',
    'Гарантія 12 місяців',
  ]

  return (
    <div className={`customContainer ${styles.specOffer}`}>
      <svg
        className={styles.specOffer_img}
        xmlns="http://www.w3.org/2000/svg"
        width="742"
        height="180"
        viewBox="0 0 742 180"
        fill="none"
      >
        <g filter="url(#filter0_b_10206_260)">
          <path
            d="M-15.2275 12.9773C-15.2275 5.24531 -8.95953 -1.02271 -1.22754 -1.02271H694.783C701.368 -1.02271 707.064 3.56696 708.463 10.002L741.739 163.002C743.637 171.729 736.99 179.977 728.059 179.977H-1.22751C-8.95949 179.977 -15.2275 173.709 -15.2275 165.977V12.9773Z"
            fill="#4F453C"
            fill-opacity="0.08"
          />
          <path
            d="M-1.22754 -0.522705H694.783C701.133 -0.522705 706.625 3.90304 707.975 10.1082L741.251 163.108C743.081 171.523 736.671 179.477 728.059 179.477H-1.22751C-8.68335 179.477 -14.7275 173.433 -14.7275 165.977V12.9773C-14.7275 5.52145 -8.68338 -0.522705 -1.22754 -0.522705Z"
            stroke="url(#paint0_linear_10206_260)"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_10206_260"
            x="-41.2275"
            y="-27.0227"
            width="809.292"
            height="233"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="13" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_10206_260"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_10206_260"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_10206_260"
            x1="6.94829"
            y1="89.4773"
            x2="767.607"
            y2="89.4773"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="0.4" stop-color="white" stop-opacity="0" />
            <stop offset="0.6" stop-color="white" stop-opacity="0" />
            <stop offset="1" stop-color="white" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="305"
        height="153"
        viewBox="0 0 305 153"
        fill="none"
        className={styles.specOffer_img_mb}
      >
        <g filter="url(#filter0_b_10221_720)">
          <path
            d="M0 13C0 5.26801 6.26801 -1 14 -1H277.53C284.707 -1 290.722 4.42805 291.456 11.5679L304.413 137.568C305.262 145.823 298.785 153 290.487 153H14C6.26801 153 0 146.732 0 139V13Z"
            fill="#4F453C"
            fill-opacity="0.08"
          />
          <path
            d="M0.5 13C0.5 5.54415 6.54416 -0.5 14 -0.5H277.53C284.451 -0.5 290.251 4.73419 290.959 11.619L303.916 137.619C304.734 145.579 298.489 152.5 290.487 152.5H14C6.54416 152.5 0.5 146.456 0.5 139V13Z"
            stroke="url(#paint0_linear_10221_720)"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_10221_720"
            x="-26"
            y="-27"
            width="356.488"
            height="206"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="13" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_10221_720"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_10221_720"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_10221_720"
            x1="8.92096"
            y1="76"
            x2="314.921"
            y2="76"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" />
            <stop offset="0.4" stop-color="white" stop-opacity="0" />
            <stop offset="0.6" stop-color="white" stop-opacity="0" />
            <stop offset="1" stop-color="white" />
          </linearGradient>
        </defs>
      </svg>
      <h2 className={styles.specOffer_title}>
        Спеціальна пропозиція <br /> для багатоквартирних будинків
      </h2>
      <p className={styles.specOffer_text}>
        Безперебійна електрика для вашої квартири всього{' '}
        <span className={styles.specOffer_text__green}> від 9000грн</span> за
        1кВт
      </p>
      <div className={styles.specOffer_wrap}>
        {cards.map((card, index) => (
          <div key={index} className={styles.specOffer_wrap__card}>
            {card}
          </div>
        ))}
      </div>
      <Link to={'form'} className={styles.specOffer_button}>
        Отримати консультацію
      </Link>
    </div>
  )
}

export default SpecOffer
