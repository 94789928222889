import React from 'react'
import '../Steps/Steps.css'
import './newsteps.css'
import {
  stepsicon1,
  stepsicon2,
  stepsicon3,
  stepsicon4,
} from '../../Assets/Icons/Steps'

const NewSteps = () => {
  return (
    <div className="newsteps customContainer" id={'steps'}>
      <div className="steps__title">Етапи роботи</div>
      <div className="newsteps_cards">
        <div className="newsteps_card_bg">
          <img className="newsteps_icon" src={stepsicon1} alt="stepsicon1" />
          <p className="newsteps_text">Онлайн консультація</p>
        </div>
        <div className="newsteps_card_bg">
          <img className="newsteps_icon" src={stepsicon2} alt="stepsicon2" />
          <p className="newsteps_text">Заключення договору</p>
        </div>
        <div className="newsteps_card_bg">
          <img className="newsteps_icon" src={stepsicon3} alt="stepsicon3" />
          <p className="newsteps_text">Оплата замовлення</p>
        </div>
        <div className="newsteps_card_bg">
          <img className="newsteps_icon" src={stepsicon4} alt="stepsicon4" />
          <p className="newsteps_text">Доставка товару</p>
        </div>
      </div>
    </div>
  )
}

export default NewSteps
